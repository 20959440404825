.nav {
  display: flex;
  align-items: center;

  &.header-primary__nav {
    @include mediaTablet {
      display: none;
    }
    & .button {
      &_tertiary {
        overflow: visible;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    gap: rem(25);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(16);
    }
  }

  &__item {
    position: relative;
    
    &_header-catalog {
      @include hover {
        & .button {
            &__icon {
              & span {
                width: 0;
              }
    
              &::before {
                transform: translateY(0);
    
                rotate: -45deg;
              }
    
              &::after {
                transform: translateY(0);
    
                rotate: 45deg;
              }
    
              &::before,
              &::after,
              & span {
                transition: transform var(--animation-timing) var(--cubic-bezier),
                  width var(--animation-timing) var(--cubic-bezier),
                  rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
              }
            }
          }
    
          &[data-elite-menu-button] {
            overflow: visible;
    
            &::after {
              content: "";
              position: absolute;
              top: 90%;
    
              width: 100%;
              height: 50px;
    
              background-color: transparent;
    
              opacity: 0;

              @include mediaBigDesktop {
                height: big(50);
              }
    
              @include mediaTablet {
                display: none;
              }
            }
          }
        }
      }
    
    &>.nav-dropdown {
      position: absolute;
      top: calc(100% + #{rem(25)});
      left: rem(-30);
      margin-top: rem(-10);

      @include mediaBigDesktop {
        top: calc(100% + #{big(25)});
        left: big(-30);
        margin-top: big(-10);
      }
      
      &_header-catalog {
        top: calc(100% + #{rem(15)});

        @include mediaBigDesktop {
          top: calc(100% + #{big(15)});
        }
        
        @include mediaLaptop {
          top: calc(100% + #{rem(12)});
        }
      }

      @include mediaLaptop {
        top: calc(100% + #{rem(22)});
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: rem(-20);
        left: 0;

        width: 100%;
        height: rem(80);

        background-color: transparent;
        opacity: 0;

        @include mediaBigDesktop {
          top: big(-20);

          height: big(80);
        }
      }
    }
    
    &>.button:not(.button_primary) {
      color: var(--text-dark-primary);
      font-weight: 400;

      &[data-elite-menu-path].active {
        &::after {
          content: "";
          position: absolute;
          top: 90%;

          width: 100%;
          height: 50px;

          background-color: transparent;

          opacity: 0;

          @include mediaBigDesktop {
            height: big(50);
          }
        }
      }

      & .button {
        &__icon {
          fill: var(--elements-gray);

          transform: scale(1);

          transition: transform var(--animation-timing) var(--cubic-bezier);

          @include mediaLaptop {
            display: none;
          }
        }
      }
    }

    @include hover {
      &>.button:not(.button_primary) {
        color: var(--accent-color);

        & .button {
          &__icon {
            fill: var(--accent-color);

            transform: rotate(180deg);
          }
        }
      }
      
      &>.nav-dropdown {
        margin: 0;

        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;  
      
    color: var(--header-text-tertiary);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-color);
    }

    &_special {
      color: var(--system-states-error);
    }
    
    &_active {
      color: var(--accent-color);
    }
  }
  
  &-dropdown {
    padding: rem(10);
    margin: 0;
    
    min-width: rem(233);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0;
    list-style: none;

    background-color: var(--bg-white);
    
    opacity: 0;
    visibility: hidden;
    
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s,
      opacity var(--animation-timing) var(--cubic-bezier) 0.25s,
      visibility var(--animation-timing) var(--cubic-bezier) 0.25s;

      @include mediaBigDesktop {
        padding: big(10);

        min-width: big(233);

        box-shadow: 0 big(4) big(8) 0 rgba(0, 0, 0, 0.1);
      }

    @include mediaLaptop {
      padding: rem(10);
    }
    
    @include mediaTablet {
      display: none;
    }

    & .nav-dropdown {
      top: 0;
      left: 100%;

      margin-top: 0;
      margin-left: rem(-10);
    
      transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s,
        opacity var(--animation-timing) var(--cubic-bezier) 0.25s,
        visibility var(--animation-timing) var(--cubic-bezier) 0.25s;

        @include mediaBigDesktop {
          margin-left: big(-10);
        }

      &::before {
        content: unset;
      }
    }

    & .nav {
      &__item {
        position: relative;
        width: 100%;
        align-items: start;
        justify-content: start;
        gap: rem(10);

        @include mediaBigDesktop {
          gap: big(10);
        }

        @include hover {
          &>.nav {
            &__link {
              //color: var(--accent-color);
              background-color: var(--bg-light);
              
              &>.nav__arrow {
                //fill: var(--accent-color);
              }
            }
            
            &-dropdown {
              margin-top: rem(-10);
              margin-left: rem(10);

              @include mediaBigDesktop {
                margin-top: big(-10);
                margin-left: big(10);
              }
            }
          }
        }
      }

      &__link {
        padding: rem(10) rem(15);
        width: 100%;
        align-items: center;
        justify-content: space-between;

        color: var(--text-dark-primary);

        transition: color var(--animation-timing) var(--cubic-bezier),
          background-color var(--animation-timing) var(--cubic-bezier);

          @include mediaBigDesktop {
            padding: big(10) big(15);
          }

        @include mediaLaptop {
          padding: rem(11.5) rem(15);
        }

        &::before {
          display: none;
        }
      }

      &__arrow {
        width: rem(16);
        height: rem(16);
        display: flex;
        flex-shrink: 0;
    
        fill: var(--elements-gray);
    
        transition: fill var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          width: big(16);
          height: big(16);
        }
      }
    }
  }
}
