@import '../../mixins';

.header {
  --header-text-tertiary: var(--text-dark-primary);
  
  position: sticky;
  top: rem(-58);
  left: 0;
  z-index: 6;

  width: 100%;
  
  transition: top var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    top: big(-58);
  }

  //@include mediaLaptop {
  //  top: rem(-44);
  //}

  @include mediaTablet {
    top: 0;
  }
  
  &_focus {
    top: 0;
  }

  &-secondary {
    padding: rem(12) 0 rem(12);

    width: 100%;
    display: flex;

    background-color: var(--header-background);

    @include mediaBigDesktop {
      padding: big(12) 0 big(12);
    }

    @include mediaLaptop {
      padding: rem(13) 0 rem(11);
    }

    @include mediaTablet {
      display: none;
    }
    
    &_light {
      --header-text-tertiary: var(--text-light-secondary);
      
      .select-current__text {
        color: var(--text-light-primary);
      }
      
      .header__location {
        fill: var(--text-light-primary);
      }
      
      .header__work-time {
        border-color: var(--stroke-light);
      }
    }

    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__block {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: rem(50);

      @include mediaBigDesktop {
        gap: big(50);
      }

      @include mediaLaptop {
        gap: rem(30);
      }

      &:last-child {
        gap: rem(25);

        @include mediaLaptop {
          gap: rem(15);
        }
      }

      &_right {
        width: auto;
        flex-shrink: 0;
      }
    }

    &__nav {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: rem(25);

      @include mediaBigDesktop {
        gap: big(25);
      }

      @include mediaLaptop {
        gap: rem(15);
      }

      &::before {
        content: "";
        position: absolute;
        left: rem(-25);
        top: 0;

        width: 1px;
        height: 100%;

        background-color: var(--stroke-dark);

        @include mediaBigDesktop {
          left: big(-25);

          width: big(1);
        }

        @include mediaLaptop {
          left: rem(-15);
        }
      }

      & .nav {
        &__item {
          white-space: nowrap;
          &:nth-child(1n+8) {
            display: none;
          }
        }
      }
    }
  }

  &-primary {
    padding: rem(15) 0;

    background-color: var(--bg-white);

    @include mediaBigDesktop {
      padding: big(15) 0;
    }

    @include mediaTablet {
      padding: rem(20) 0;
    }

    @include mediaMobile {
      padding: rem(15) 0;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        gap: rem(10);
      }

      @include mediaMobile {
        gap: 0;
      }
    }

    &__block {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(30);

      flex-shrink: 0;

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaLaptop {
        gap: rem(25);
      }

      @include mediaTablet {
        gap: rem(20);
      }

      @include mediaMobile {
        gap: rem(15);
      }

      &.left {
        @include mediaTablet {
          flex-direction: row-reverse;
        }
      }

      &.right {
        gap: rem(10);
        flex-shrink: 1;

        @include mediaBigDesktop {
          gap: big(10);
        }

        @include mediaLaptop {
          width: 100%;
        }

        @include mediaMobile {
          gap: rem(5);
          justify-content: end;
        }
      }
    }
  }

  &-catalog {
    margin-top: rem(3);
    padding: rem(11) rem(15);

    height: rem(44);
    min-height: unset;

    @include mediaBigDesktop {
      margin-top: big(3);
      padding: big(11) big(15);

      height: big(44);
    }

    @include mediaLaptop {
      margin-top: 0;
      margin-left: rem(-9);
    }

    @include mediaTablet {
      margin-left: 0;
      padding: 0;

      height: rem(44);
      width: rem(44);
    }

    @include mediaMobile {
      width: rem(34);
      height: rem(34);
    }
    
    &_hover {
      @include hover {
      & .button {
        &__icon {
          & span {
            width: 0;
          }

          &::before {
            transform: translateY(0);

            rotate: -45deg;
          }

          &::after {
            transform: translateY(0);

            rotate: 45deg;
          }

          &::before,
          &::after,
          & span {
            transition: transform var(--animation-timing) var(--cubic-bezier),
              width var(--animation-timing) var(--cubic-bezier),
              rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
          }
        }
      }

      &[data-elite-menu-button] {
        overflow: visible;

        &::after {
          content: "";
          position: absolute;
          top: 90%;

          width: 100%;
          height: 50px;

          background-color: transparent;

          opacity: 0;

          @include mediaBigDesktop {
            height: big(50);
          }

          @include mediaTablet {
            display: none;
          }
        }
      }

      }
    }

    &.active {
      & .button {
        &__icon {
          & span {
            width: 0;
          }

          &::before {
            transform: translateY(0);

            rotate: -45deg;
          }

          &::after {
            transform: translateY(0);

            rotate: 45deg;
          }

          &::before,
          &::after,
          & span {
            transition: transform var(--animation-timing) var(--cubic-bezier),
              width var(--animation-timing) var(--cubic-bezier),
              rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
          }
        }
      }

      &[data-elite-menu-button] {
        overflow: visible;

        &::after {
          content: "";
          position: absolute;
          top: 90%;

          width: 100%;
          height: 50px;

          background-color: transparent;

          opacity: 0;

          @include mediaBigDesktop {
            height: big(50);
          }

          @include mediaTablet {
            display: none;
          }
        }
      }
    }

    & .button {
      &__icon {
        position: relative;

        &::before,
        &::after,
        & span {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          width: rem(15);
          height: rem(2);

          background-color: var(--bg-white);

          transition: transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing),
            width var(--animation-timing) var(--cubic-bezier),
            rotate var(--animation-timing) var(--cubic-bezier);

            @include mediaBigDesktop {
              width: big(15);
              height: big(2);
            }
        }

        &::before {
          transform: translateY(rem(-5));
        }

        &::after {
          transform: translateY(rem(5));
        }
      }

      &__text {
        @include mediaTablet {
          display: none;
        }
      }
    }
  }

  &__logo {
    margin-right: rem(10);

    @include mediaBigDesktop {
      margin-right: big(10);
    }
  }

  &__location {
    width: rem(16);
    height: rem(16);
    flex-shrink: 0;

    fill: var(--elements-dark);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }

    &_stroke {
      fill: none;
      stroke: var(--elements-dark);
      stroke-width: 1px;
    }
  }

  &__select {
    width: fit-content;

    & .select {
      &-current {
        padding: 0;
        margin: 0;

        width: fit-content;
        gap: rem(5);

        background-color: transparent;
        border: none;

        @include mediaBigDesktop {
          gap: big(5);
        }

        &__text {
          font-size: rem(14);

          @include mediaBigDesktop {
            font-size: big(14);
          }

          @include mediaLaptop {
            font-size: rem(12);
          }

          &.bold {
            font-weight: 600;
          }
        }
      }

      &-answer {
        width: auto;
        max-width: 600px;

        &__item {
          padding: rem(5) rem(7);

          @include mediaBigDesktop {
            padding: big(5) big(7);
          }
        }

        &__text {
          font-size: rem(14);

          @include mediaBigDesktop {
            font-size: big(14);
          }

          @include mediaLaptop {
            font-size: rem(12);
          }
        }

        a {
          color: var(--text-dark-primary);
          text-decoration: none;
        }
      }
    }
  }

  &__work-time {
    position: relative;
    padding-left: rem(25);

    color: var(--header-text-tertiary);

    border-left: solid 1px var(--stroke-dark);

    @include mediaBigDesktop {
      padding-left: big(25);

      border-left-width: big(1);
    }

    @include mediaLaptop {
      padding-left: rem(15);
    }

    .select-answer {
      line-height: 1.9;
      display: inline-block;
      min-width: 22rem;

      .text {
        line-height: 1.2;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }

  &__call {
    color: var(--main-secondary);
    font-weight: 400;
    
    @include hover {
      color: var(--main-secondary);
    }
  }

  &__search {
    border: solid 1px var(--bg-light);

    transition: border-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      border-width: big(1);
    }

    @include mediaMobile {
      position: absolute;
      
      border: none;
      opacity: 0;
      visibility: hidden;
    }

    @include hover {
      border: solid 1px var(--accent-color);

      @include mediaBigDesktop {
        border-width: big(1);
      }
    }
  }

  &__shared {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

@import "nav",
"header-more";
