.header-more {
  display: none;

  &_show {
    display: flex;
  }

  & .select-answer {
    left: unset;
    right: 0;

    width: fit-content;

    white-space: nowrap;
  }

  & .select-current {
    @include hover {
      & .select-current {
        &__icon {
          fill: var(--accent-color);
        }
      }
    }

    &::before {
      @include pseudo(10)
    }

    &__icon {
      transform: none !important;

      transition: fill var(--animation-timing) var(--cubic-bezier);
    }
  }
}
