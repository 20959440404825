.header {
  --header-text-tertiary: var(--text-dark-primary);
  position: -webkit-sticky;
  position: sticky;
  top: -3.625rem;
  left: 0;
  z-index: 6;
  width: 100%;
  -webkit-transition: top var(--animation-timing) var(--cubic-bezier);
  -o-transition: top var(--animation-timing) var(--cubic-bezier);
  transition: top var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .header {
      top: -2.26562vw; } }
  @media screen and (max-width: 87.5rem) {
    .header {
      top: 0; } }
  .header_focus {
    top: 0; }
  .header-secondary {
    padding: 0.75rem 0 0.75rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--header-background); }
    @media screen and (min-width: 160.0625rem) {
      .header-secondary {
        padding: 0.46875vw 0 0.46875vw; } }
    @media screen and (max-width: 109.375rem) {
      .header-secondary {
        padding: 0.8125rem 0 0.6875rem; } }
    @media screen and (max-width: 87.5rem) {
      .header-secondary {
        display: none; } }
    .header-secondary_light {
      --header-text-tertiary: var(--text-light-secondary); }
      .header-secondary_light .select-current__text {
        color: var(--text-light-primary); }
      .header-secondary_light .header__location {
        fill: var(--text-light-primary); }
      .header-secondary_light .header__work-time {
        border-color: var(--stroke-light); }
    .header-secondary__container {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .header-secondary__block {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      gap: 3.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .header-secondary__block {
          gap: 1.95312vw; } }
      @media screen and (max-width: 109.375rem) {
        .header-secondary__block {
          gap: 1.875rem; } }
      .header-secondary__block:last-child {
        gap: 1.5625rem; }
        @media screen and (max-width: 109.375rem) {
          .header-secondary__block:last-child {
            gap: 0.9375rem; } }
      .header-secondary__block_right {
        width: auto;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
    .header-secondary__nav {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      gap: 1.5625rem; }
      @media screen and (min-width: 160.0625rem) {
        .header-secondary__nav {
          gap: 0.97656vw; } }
      @media screen and (max-width: 109.375rem) {
        .header-secondary__nav {
          gap: 0.9375rem; } }
      .header-secondary__nav::before {
        content: "";
        position: absolute;
        left: -1.5625rem;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: var(--stroke-dark); }
        @media screen and (min-width: 160.0625rem) {
          .header-secondary__nav::before {
            left: -0.97656vw;
            width: 0.03906vw; } }
        @media screen and (max-width: 109.375rem) {
          .header-secondary__nav::before {
            left: -0.9375rem; } }
      .header-secondary__nav .nav__item {
        white-space: nowrap; }
        .header-secondary__nav .nav__item:nth-child(1n+8) {
          display: none; }
  .header-primary {
    padding: 0.9375rem 0;
    background-color: var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .header-primary {
        padding: 0.58594vw 0; } }
    @media screen and (max-width: 87.5rem) {
      .header-primary {
        padding: 1.25rem 0; } }
    @media screen and (max-width: 39.375rem) {
      .header-primary {
        padding: 0.9375rem 0; } }
    .header-primary__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .header-primary__container {
          gap: 0.78125vw; } }
      @media screen and (max-width: 87.5rem) {
        .header-primary__container {
          gap: 0.625rem; } }
      @media screen and (max-width: 39.375rem) {
        .header-primary__container {
          gap: 0; } }
    .header-primary__block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 1.875rem;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      @media screen and (min-width: 160.0625rem) {
        .header-primary__block {
          gap: 1.17188vw; } }
      @media screen and (max-width: 109.375rem) {
        .header-primary__block {
          gap: 1.5625rem; } }
      @media screen and (max-width: 87.5rem) {
        .header-primary__block {
          gap: 1.25rem; } }
      @media screen and (max-width: 39.375rem) {
        .header-primary__block {
          gap: 0.9375rem; } }
      @media screen and (max-width: 87.5rem) {
        .header-primary__block.left {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
                  flex-direction: row-reverse; } }
      .header-primary__block.right {
        gap: 0.625rem;
        -ms-flex-negative: 1;
            flex-shrink: 1; }
        @media screen and (min-width: 160.0625rem) {
          .header-primary__block.right {
            gap: 0.39062vw; } }
        @media screen and (max-width: 109.375rem) {
          .header-primary__block.right {
            width: 100%; } }
        @media screen and (max-width: 39.375rem) {
          .header-primary__block.right {
            gap: 0.3125rem;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: end; } }
  .header-catalog {
    margin-top: 0.1875rem;
    padding: 0.6875rem 0.9375rem;
    height: 2.75rem;
    min-height: unset; }
    @media screen and (min-width: 160.0625rem) {
      .header-catalog {
        margin-top: 0.11719vw;
        padding: 0.42969vw 0.58594vw;
        height: 1.71875vw; } }
    @media screen and (max-width: 109.375rem) {
      .header-catalog {
        margin-top: 0;
        margin-left: -0.5625rem; } }
    @media screen and (max-width: 87.5rem) {
      .header-catalog {
        margin-left: 0;
        padding: 0;
        height: 2.75rem;
        width: 2.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .header-catalog {
        width: 2.125rem;
        height: 2.125rem; } }
    @media (any-hover: hover) {
      .header-catalog_hover:hover .button__icon span {
        width: 0; }
      .header-catalog_hover:hover .button__icon::before {
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
        rotate: -45deg; }
      .header-catalog_hover:hover .button__icon::after {
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
        rotate: 45deg; }
      .header-catalog_hover:hover .button__icon::before, .header-catalog_hover:hover .button__icon::after,
      .header-catalog_hover:hover .button__icon span {
        -webkit-transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
        transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
        -o-transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier); }
      .header-catalog_hover:hover[data-elite-menu-button] {
        overflow: visible; }
        .header-catalog_hover:hover[data-elite-menu-button]::after {
          content: "";
          position: absolute;
          top: 90%;
          width: 100%;
          height: 50px;
          background-color: transparent;
          opacity: 0; } }
    @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
      .header-catalog_hover:hover[data-elite-menu-button]::after {
        height: 1.95312vw; } }
    @media screen and (any-hover: hover) and (max-width: 87.5rem) {
      .header-catalog_hover:hover[data-elite-menu-button]::after {
        display: none; } }
    .header-catalog.active .button__icon span {
      width: 0; }
    .header-catalog.active .button__icon::before {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      rotate: -45deg; }
    .header-catalog.active .button__icon::after {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      rotate: 45deg; }
    .header-catalog.active .button__icon::before, .header-catalog.active .button__icon::after,
    .header-catalog.active .button__icon span {
      -webkit-transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
      transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
      -o-transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
      transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
      transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier); }
    .header-catalog.active[data-elite-menu-button] {
      overflow: visible; }
      .header-catalog.active[data-elite-menu-button]::after {
        content: "";
        position: absolute;
        top: 90%;
        width: 100%;
        height: 50px;
        background-color: transparent;
        opacity: 0; }
        @media screen and (min-width: 160.0625rem) {
          .header-catalog.active[data-elite-menu-button]::after {
            height: 1.95312vw; } }
        @media screen and (max-width: 87.5rem) {
          .header-catalog.active[data-elite-menu-button]::after {
            display: none; } }
    .header-catalog .button__icon {
      position: relative; }
      .header-catalog .button__icon::before, .header-catalog .button__icon::after,
      .header-catalog .button__icon span {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 0.9375rem;
        height: 0.125rem;
        background-color: var(--bg-white);
        -webkit-transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier), -webkit-transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier), -webkit-transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        -o-transition: transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier);
        transition: transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier);
        transition: transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier), -webkit-transform var(--animation-timing) var(--cubic-bezier) var(--animation-timing); }
        @media screen and (min-width: 160.0625rem) {
          .header-catalog .button__icon::before, .header-catalog .button__icon::after,
          .header-catalog .button__icon span {
            width: 0.58594vw;
            height: 0.07812vw; } }
      .header-catalog .button__icon::before {
        -webkit-transform: translateY(-0.3125rem);
            -ms-transform: translateY(-0.3125rem);
                transform: translateY(-0.3125rem); }
      .header-catalog .button__icon::after {
        -webkit-transform: translateY(0.3125rem);
            -ms-transform: translateY(0.3125rem);
                transform: translateY(0.3125rem); }
    @media screen and (max-width: 87.5rem) {
      .header-catalog .button__text {
        display: none; } }
  .header__logo {
    margin-right: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .header__logo {
        margin-right: 0.39062vw; } }
  .header__location {
    width: 1rem;
    height: 1rem;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    fill: var(--elements-dark); }
    @media screen and (min-width: 160.0625rem) {
      .header__location {
        width: 0.625vw;
        height: 0.625vw; } }
    .header__location_stroke {
      fill: none;
      stroke: var(--elements-dark);
      stroke-width: 1px; }
  .header__select {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .header__select .select-current {
      padding: 0;
      margin: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      gap: 0.3125rem;
      background-color: transparent;
      border: none; }
      @media screen and (min-width: 160.0625rem) {
        .header__select .select-current {
          gap: 0.19531vw; } }
      .header__select .select-current__text {
        font-size: 0.875rem; }
        @media screen and (min-width: 160.0625rem) {
          .header__select .select-current__text {
            font-size: 0.54688vw; } }
        @media screen and (max-width: 109.375rem) {
          .header__select .select-current__text {
            font-size: 0.75rem; } }
        .header__select .select-current__text.bold {
          font-weight: 600; }
    .header__select .select-answer {
      width: auto;
      max-width: 600px; }
      .header__select .select-answer__item {
        padding: 0.3125rem 0.4375rem; }
        @media screen and (min-width: 160.0625rem) {
          .header__select .select-answer__item {
            padding: 0.19531vw 0.27344vw; } }
      .header__select .select-answer__text {
        font-size: 0.875rem; }
        @media screen and (min-width: 160.0625rem) {
          .header__select .select-answer__text {
            font-size: 0.54688vw; } }
        @media screen and (max-width: 109.375rem) {
          .header__select .select-answer__text {
            font-size: 0.75rem; } }
      .header__select .select-answer a {
        color: var(--text-dark-primary);
        text-decoration: none; }
  .header__work-time {
    position: relative;
    padding-left: 1.5625rem;
    color: var(--header-text-tertiary);
    border-left: solid 1px var(--stroke-dark); }
    @media screen and (min-width: 160.0625rem) {
      .header__work-time {
        padding-left: 0.97656vw;
        border-left-width: 0.03906vw; } }
    @media screen and (max-width: 109.375rem) {
      .header__work-time {
        padding-left: 0.9375rem; } }
    .header__work-time .select-answer {
      line-height: 1.9;
      display: inline-block;
      min-width: 22rem; }
      .header__work-time .select-answer .text {
        line-height: 1.2; }
        .header__work-time .select-answer .text:not(:first-child) {
          margin-top: 10px; }
  .header__call {
    color: var(--main-secondary);
    font-weight: 400; }
    @media (any-hover: hover) {
      .header__call:hover {
        color: var(--main-secondary); } }
  .header__search {
    border: solid 1px var(--bg-light);
    -webkit-transition: border-color var(--animation-timing) var(--cubic-bezier);
    -o-transition: border-color var(--animation-timing) var(--cubic-bezier);
    transition: border-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .header__search {
        border-width: 0.03906vw; } }
    @media screen and (max-width: 39.375rem) {
      .header__search {
        position: absolute;
        border: none;
        opacity: 0;
        visibility: hidden; } }
    @media (any-hover: hover) {
      .header__search:hover {
        border: solid 1px var(--accent-color); } }
  @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
    .header__search:hover {
      border-width: 0.03906vw; } }
  .header__shared {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    gap: 10px; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 87.5rem) {
    .nav.header-primary__nav {
      display: none; } }
  .nav.header-primary__nav .button_tertiary {
    overflow: visible; }
  .nav__list {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 1.5625rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .nav__list {
        gap: 0.78125vw; } }
    @media screen and (max-width: 109.375rem) {
      .nav__list {
        gap: 1rem; } }
  .nav__item {
    position: relative; }
    @media (any-hover: hover) {
      .nav__item_header-catalog:hover .button__icon span {
        width: 0; }
      .nav__item_header-catalog:hover .button__icon::before {
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
        rotate: -45deg; }
      .nav__item_header-catalog:hover .button__icon::after {
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
        rotate: 45deg; }
      .nav__item_header-catalog:hover .button__icon::before, .nav__item_header-catalog:hover .button__icon::after,
      .nav__item_header-catalog:hover .button__icon span {
        -webkit-transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
        transition: width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier);
        -o-transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing);
        transition: transform var(--animation-timing) var(--cubic-bezier), width var(--animation-timing) var(--cubic-bezier), rotate var(--animation-timing) var(--cubic-bezier) var(--animation-timing), -webkit-transform var(--animation-timing) var(--cubic-bezier); }
      .nav__item_header-catalog:hover[data-elite-menu-button] {
        overflow: visible; }
        .nav__item_header-catalog:hover[data-elite-menu-button]::after {
          content: "";
          position: absolute;
          top: 90%;
          width: 100%;
          height: 50px;
          background-color: transparent;
          opacity: 0; } }
    @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
      .nav__item_header-catalog:hover[data-elite-menu-button]::after {
        height: 1.95312vw; } }
    @media screen and (any-hover: hover) and (max-width: 87.5rem) {
      .nav__item_header-catalog:hover[data-elite-menu-button]::after {
        display: none; } }
    .nav__item > .nav-dropdown {
      position: absolute;
      top: calc(100% + 1.5625rem);
      left: -1.875rem;
      margin-top: -0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .nav__item > .nav-dropdown {
          top: calc(100% + 0.97656vw);
          left: -1.17188vw;
          margin-top: -0.39062vw; } }
      .nav__item > .nav-dropdown_header-catalog {
        top: calc(100% + 0.9375rem); }
        @media screen and (min-width: 160.0625rem) {
          .nav__item > .nav-dropdown_header-catalog {
            top: calc(100% + 0.58594vw); } }
        @media screen and (max-width: 109.375rem) {
          .nav__item > .nav-dropdown_header-catalog {
            top: calc(100% + 0.75rem); } }
      @media screen and (max-width: 109.375rem) {
        .nav__item > .nav-dropdown {
          top: calc(100% + 1.375rem); } }
      .nav__item > .nav-dropdown::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: -1.25rem;
        left: 0;
        width: 100%;
        height: 5rem;
        background-color: transparent;
        opacity: 0; }
        @media screen and (min-width: 160.0625rem) {
          .nav__item > .nav-dropdown::before {
            top: -0.78125vw;
            height: 3.125vw; } }
    .nav__item > .button:not(.button_primary) {
      color: var(--text-dark-primary);
      font-weight: 400; }
      .nav__item > .button:not(.button_primary)[data-elite-menu-path].active::after {
        content: "";
        position: absolute;
        top: 90%;
        width: 100%;
        height: 50px;
        background-color: transparent;
        opacity: 0; }
        @media screen and (min-width: 160.0625rem) {
          .nav__item > .button:not(.button_primary)[data-elite-menu-path].active::after {
            height: 1.95312vw; } }
      .nav__item > .button:not(.button_primary) .button__icon {
        fill: var(--elements-gray);
        -webkit-transform: scale(1);
            -ms-transform: scale(1);
                transform: scale(1);
        -webkit-transition: -webkit-transform var(--animation-timing) var(--cubic-bezier);
        transition: -webkit-transform var(--animation-timing) var(--cubic-bezier);
        -o-transition: transform var(--animation-timing) var(--cubic-bezier);
        transition: transform var(--animation-timing) var(--cubic-bezier);
        transition: transform var(--animation-timing) var(--cubic-bezier), -webkit-transform var(--animation-timing) var(--cubic-bezier); }
        @media screen and (max-width: 109.375rem) {
          .nav__item > .button:not(.button_primary) .button__icon {
            display: none; } }
    @media (any-hover: hover) {
      .nav__item:hover > .button:not(.button_primary) {
        color: var(--accent-color); }
        .nav__item:hover > .button:not(.button_primary) .button__icon {
          fill: var(--accent-color);
          -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
                  transform: rotate(180deg); }
      .nav__item:hover > .nav-dropdown {
        margin: 0;
        opacity: 1;
        visibility: visible; } }
  .nav__link {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: var(--header-text-tertiary);
    text-decoration: none;
    -webkit-transition: color var(--animation-timing) var(--cubic-bezier);
    -o-transition: color var(--animation-timing) var(--cubic-bezier);
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .nav__link:hover {
        color: var(--accent-color); } }
    .nav__link_special {
      color: var(--system-states-error); }
    .nav__link_active {
      color: var(--accent-color); }
  .nav-dropdown {
    padding: 0.625rem;
    margin: 0;
    min-width: 14.5625rem;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    gap: 0;
    list-style: none;
    background-color: var(--bg-white);
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s;
    -o-transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s;
    transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s; }
    @media screen and (min-width: 160.0625rem) {
      .nav-dropdown {
        padding: 0.39062vw;
        min-width: 9.10156vw;
        -webkit-box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.1); } }
    @media screen and (max-width: 109.375rem) {
      .nav-dropdown {
        padding: 0.625rem; } }
    @media screen and (max-width: 87.5rem) {
      .nav-dropdown {
        display: none; } }
    .nav-dropdown .nav-dropdown {
      top: 0;
      left: 100%;
      margin-top: 0;
      margin-left: -0.625rem;
      -webkit-transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s;
      -o-transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s;
      transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s; }
      @media screen and (min-width: 160.0625rem) {
        .nav-dropdown .nav-dropdown {
          margin-left: -0.39062vw; } }
      .nav-dropdown .nav-dropdown::before {
        content: unset; }
    .nav-dropdown .nav__item {
      position: relative;
      width: 100%;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
      gap: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .nav-dropdown .nav__item {
          gap: 0.39062vw; } }
      @media (any-hover: hover) {
        .nav-dropdown .nav__item:hover > .nav__link {
          background-color: var(--bg-light); }
        .nav-dropdown .nav__item:hover > .nav-dropdown {
          margin-top: -0.625rem;
          margin-left: 0.625rem; } }
  @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
    .nav-dropdown .nav__item:hover > .nav-dropdown {
      margin-top: -0.39062vw;
      margin-left: 0.39062vw; } }
    .nav-dropdown .nav__link {
      padding: 0.625rem 0.9375rem;
      width: 100%;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      color: var(--text-dark-primary);
      -webkit-transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier);
      -o-transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier);
      transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .nav-dropdown .nav__link {
          padding: 0.39062vw 0.58594vw; } }
      @media screen and (max-width: 109.375rem) {
        .nav-dropdown .nav__link {
          padding: 0.71875rem 0.9375rem; } }
      .nav-dropdown .nav__link::before {
        display: none; }
    .nav-dropdown .nav__arrow {
      width: 1rem;
      height: 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      fill: var(--elements-gray);
      -webkit-transition: fill var(--animation-timing) var(--cubic-bezier);
      -o-transition: fill var(--animation-timing) var(--cubic-bezier);
      transition: fill var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .nav-dropdown .nav__arrow {
          width: 0.625vw;
          height: 0.625vw; } }

.header-more {
  display: none; }
  .header-more_show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .header-more .select-answer {
    left: unset;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    white-space: nowrap; }
  @media (any-hover: hover) {
    .header-more .select-current:hover .select-current__icon {
      fill: var(--accent-color); } }
  .header-more .select-current::before {
    content: "";
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
    width: calc(100% + 0.625rem * 2);
    height: calc(100% + 0.625rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .header-more .select-current::before {
        top: -0.39062vw;
        left: -0.39062vw;
        width: calc(100% + 0.39062vw * 2);
        height: calc(100% + 0.39062vw * 2); } }
  .header-more .select-current__icon {
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important;
    -webkit-transition: fill var(--animation-timing) var(--cubic-bezier);
    -o-transition: fill var(--animation-timing) var(--cubic-bezier);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
